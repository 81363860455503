<template>
  <div>
    <div class="columns">
      <div class="column">
        <h2 class="title is-3">Список аккаунтов ({{ vkTokens.length }})</h2>
      </div>
      <div class="column">
        <b-button
          class="is-pulled-right"
          type="is-primary"
          icon-right="plus"
          @click="createVkToken()"
        >Добавить аккаунт</b-button
        >
      </div>
    </div>

    <!-- ТАБЛИЦА -->
    <b-table
      :data="vkTokens"
      class="users-table"
    >
      <b-table-column label="Включен" v-slot="props">
        <b-switch :value="props.row.isUsed" @change.native="changeUsage(props.row.id)"></b-switch>
      </b-table-column>

      <b-table-column label="Имя" v-slot="props">
        <span>{{ props.row.name }}</span>
      </b-table-column>

      <b-table-column label="Действия" v-slot="props">
        <div>
          <b-tooltip label="Обновить аккаунт" v-if="authUser.role ==='admin' || authUser.role ==='superuser'">
            <b-button class="edit_icon" @click="updateVkToken(props.row)">
              <svg viewBox="0 0 24 24" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"></path>
                <path d="M13.5 6.5l4 4"></path>
              </svg>
            </b-button>
          </b-tooltip>

          <b-tooltip label="Удалить аккаунт" v-if="authUser.role === 'admin' || authUser.role ==='superuser'">
            <b-button
              class="delete_icon"
              @click="deleteVkToken(props.row)">
              <svg viewBox="0 0 24 24" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                <path d="M4 7l16 0"></path>
                <path d="M10 11l0 6"></path>
                <path d="M14 11l0 6"></path>
                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
              </svg>
            </b-button>
          </b-tooltip>
        </div>
      </b-table-column>
    </b-table>

    <VkDetailsModal></VkDetailsModal>
    <ConfirmDeletionVkModal></ConfirmDeletionVkModal>
  </div>
</template>

<script>
import VkDetailsModal from '@/app/client/vk/shared/modals/vkDetailsModal.vue';
import ConfirmDeletionVkModal from '@/app/client/vk/shared/modals/confirmDeletionVkModal.vue';

export default {
  name: "VkList",
  components: {
    VkDetailsModal,
    ConfirmDeletionVkModal,
  },
  data() {
    return {
    };
  },
  created() {
    this.$store.dispatch("GET_VK_TOKENS");
  },
  computed: {
    authUser() {
      return this.$store.getters.AUTH_USER;
    },
    vkTokens() {
      return this.$store.getters.VK_TOKENS;
    },
  },
  methods: {
    createVkToken() {
      this.$store.dispatch("VK_DETAILS_MODAL", true);
    },
    updateVkToken(template) {
      this.$store.dispatch("CURRENT_VK_TOKEN", template);
      this.$store.dispatch("VK_DETAILS_MODAL", true);
    },
    deleteVkToken(template) {
      this.$store.dispatch("CURRENT_VK_TOKEN", template);
      this.$store.dispatch("CONFIRM_DELETION_VK_MODAL", true);
    },
    changeUsage(id) {
      this.$store
          .dispatch("CHANGE_USAGE", id)
          .then(() => {})
          .catch(() => {
              this.$toast.error("Неизвестная ошибка!");
          });
    },
  },
};
</script>
