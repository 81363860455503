<template>
  <v-dialog
    v-model="isOpen"
    max-width="1000"
  >
    <v-card>
      <v-card-title class="headline pb-10">
        <div class="columns">
          <div class="column" v-if="currentVkToken.id == null">
            <h2 class="title is-3">Создать аккаунт</h2>
          </div>
          <div class="column" v-if="currentVkToken.id != null">
            <h2 class="title is-3">Изменить аккаунт: {{ currentVkToken.name }}</h2>
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-layout row>
          <v-layout column>
            <b-field label="Название">
              <b-input v-model="currentVkToken.name" :disabled="currentVkToken.id != null"></b-input>
            </b-field>
            <b-field label="Client_id VK аккаунта">
              <b-input v-model="currentVkToken.client_id" :disabled="currentVkToken.id != null"></b-input>
            </b-field>
            <b-field label="Client_secret VK аккаунта">
              <b-input v-model="currentVkToken.client_secret" :disabled="currentVkToken.id != null"></b-input>
            </b-field>

            <b-field label="Список менеджеров" v-if="currentVkToken.id != null">
              <!-- ТАБЛИЦА -->
              <b-table
                :data="currentVkToken.accounts"
                class="users-table"

                :per-page="perPage"
                paginated
              >
                <b-table-column label="" v-slot="props">
                  <b-switch :value="props.row.isUsed" @change.native="changeUsage(props.row.id)"></b-switch>
                </b-table-column>

                <b-table-column label="" v-slot="props">
                  <span>{{ props.row.name }}</span>
                </b-table-column>

                <!-- Пагинация и выбор количества элементов на странице -->
                <template slot="bottom-left">
                  <div class="pagination-controls">
                    <v-select
                      style="width: fit-content; float: left;"
                      v-model="perPage"
                      :items="perPageOptions"
                      item-text="name"
                      item-value="value"
                      outlined
                      hide-details
                      dense
                    />
                  </div>
                </template>
              </b-table>

            </b-field>
          </v-layout>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close()">Закрыть</v-btn>
        <v-btn text color="primary" v-if="currentVkToken.id == null" @click="createVkToken()">Добавить</v-btn>
      </v-card-actions>
      <overlay-loader :show="isLoading"></overlay-loader>
    </v-card>
  </v-dialog>
</template>

<script>
import OverlayLoader from '@/app/shared/components/app-loader/Loader';
import draggable from "vuedraggable";

export default {
  name: 'VkDetailsModal',
  components: {
    OverlayLoader,
    draggable,
  },
  data() {
    return {
      headers: [
          { text: "Используем?", sortable: true, value: "isUsed", class: "level head_table" },
          { text: "Имя клиента", sortable: true, value: "name", class: "level head_table" }
      ],

      /* для пагинации */
      perPage: 10,
      perPageOptions: [
        { name: "10 на странице", value: 10 },
        { name: "20 на странице", value: 20 },
        { name: "50 на странице", value: 50 },
        { name: "100 на странице", value: 100 },
      ],
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters.IS_CURRENT_VK_TOKEN_LOADING;
    },
    isOpen() {
      return this.$store.getters.IS_VK_DETAILS_MODAL_OPENED;
    },
    currentVkToken: {
      get() {
        return this.$store.getters.GET_CURRENT_VK_TOKEN;
      },
      set(value) {
        this.$store.commit("SET_CURRENT_VK_TOKEN", value);
      },
    },
  },
  methods: {
    close() {
      this.$store.dispatch("CURRENT_VK_TOKEN", {});
      this.$store.dispatch('VK_DETAILS_MODAL', false);
    },
    createVkToken(){
      this.$store.dispatch('CREATE_VK_TOKEN', this.currentVkToken)
        .then(() => {
          this.$toast.success("Аккаунт успешно добавлен");
          this.$store.dispatch("CURRENT_VK_TOKEN", { });
          this.$store.dispatch("GET_VK_TOKENS");
          this.$store.dispatch('VK_DETAILS_MODAL', false);
        })
        .catch(() => {
          this.$toast.error("Ошибка при добавлении аккаунта");
        });
    },
    changeUsage(id) {
      this.$store
          .dispatch("CHANGE_USAGE", id)
          .then(() => {})
          .catch(() => {
              this.$toast.error("Неизвестная ошибка!");
          });
    },
  },
};
</script>
<style>
.draggable-item {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 10px;
  background-color: #fff;
}
</style>
